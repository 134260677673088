<template>
  <div>
      <van-cell-group>
        <van-cell icon="bars"
                  title="资料分类"></van-cell>
        <template v-for="(cat, ind) in catTree">
          <van-cell :key="ind"
                    :icon="cat.children_num?'arrow-down':'arrow'"
                    :arrow-direction="cat.children_num?'down':'left'"
                    :title="cat.classname"
                    is-link
                    @click="changeCat(cat)"
                    :title-style="{color: cat.children_num?'#1989fa':''}"></van-cell>
          <van-cell v-for="(sub, key) in cat.children"
                    :key="key"
                    icon="arrow"
                    :title="sub.classname"
                    is-link
                    @click="changeCat(sub)"></van-cell>
        </template>
      </van-cell-group>

  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, CellGroup } from 'vant'
Vue.use(Cell).use(CellGroup)

export default {
  name: 'category',
  data () {
    return {
    }
  },
  computed: {
    catTree () {
      return this.$store.state.catTree || [];
    }
  },
  methods: {
    getInit () {
      this.$store.dispatch('getInit');
    },
    changeCat (cat) {
      this.$router.push({ name: 'goodsList', params: { cid: cat.id } })
    }
  },
  activated () {
    this.getInit();
  },
  mounted () {
    this.height = window.screen.height - 240;
  }
}
</script>

<style>
</style>